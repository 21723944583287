import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import { createPinia } from 'pinia'

import AppBody from './components/AppBody.vue'
import router from './router.ts'
// import { vuetify } from './middleware/index.ts'
import './styles/main.scss'

const pinia = createPinia()
const head = createHead()

createApp(AppBody)
  .use(router)
  .use(pinia)
  .use(head)
  // .use(vuetify)
  .mount('#app')
