import type { NavItem } from '../types'

export const HEADER_LINKS: NavItem[] = [
  {
    aria: 'Lore',
    name: 'Lore',
    link: 'loriet',
    theme: 'lore'
  }, 
  {
    aria: 'RJ',
    name: 'RJ',
    link: 'rj',
    theme: 'heart'
  },
]

export const FOOTER_LINKS: NavItem[] = [
  {
    aria: 'Home',
    name: '©2024',
    link: '',
    theme: 'green-light'
  }, 
  {
    aria: 'Loriet',
    name: 'Lore',
    link: 'loriet',
    theme: 'lore'
  }, 
  {
    aria: 'RJ',
    name: 'Heart',
    link: 'rj',
    theme: 'heart'
  }, 
]